import React from 'react'
import styled from 'styled-components'
import { device } from './GlobalStyles/helpers/device'
import Img from 'gatsby-image'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'

const Container = styled.div`
  .image {
    width: 100%;
    vertical-align: top;
    object-fit: cover;

    @media ${device.laptop} {
      width: 50%;
      max-width: 800px;
      clip-path: polygon(100% 100%, 100% 0px, 100px 0, 0px 100%);
      min-height: 100vh;

      .image {
        width: 50%;
        max-width: 800px;
        clip-path: polygon(100% 100%, 100% 0px, 100px 0, 0px 100%);
      }
    }
  }

  @media ${device.laptop} {
    display: flex;
    flex-direction: row-reverse;
  }
`

const Body = styled.div`
  @media ${device.laptop} {
    flex-grow: 2;
    max-width: 50%;
  }
`

interface Props {
  className?: string
  alt?: string
  src?: string
  children?: React.ReactNode
  fluid?: GatsbyFluidImageProps
}

export const SplitImage = ({ src, fluid, alt, className, children }: Props): JSX.Element => {
  return (
    <Container className={className}>
      {fluid && <Img className="image" fluid={fluid} alt={alt} />}
      {src && <img className="image" src={src} alt={alt} />}
      <Body>{children}</Body>
    </Container>
  )
}
