import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { BlockContent } from '../components/BlockContent'
import { Layout } from '../components/Layout/Layout'
import { SEO } from '../helpers/SEO'
import { imageUrlFor, buildImageObj } from '../helpers/imageUrl.js'
import { SplitImage } from '../components/SplitImage'
import { Service } from '../definitions/service'
import { NavButton } from '../components/atoms/ui/buttons'

const PageWrapper = styled.div`
  a.cta {
    margin-left: 5%;
  }
`

interface Data {
  data: {
    service: Service
  }
}

const ServicePage = ({ data }: Data): JSX.Element => {
  const { service } = data

  const mainImage =
    service.mainImage &&
    imageUrlFor(buildImageObj(service.mainImage))
      .width(800)
      .height(Math.floor((4 / 3) * 800))
      .fit('crop')
      .auto('format')
      .url()

  return (
    <Layout>
      <SEO
        title={service.seoData?.title || 'Service'}
        description={service.seoData._rawDescription}
        location={service.seoData?.location}
      />

      <PageWrapper>
        {service.mainImage && (
          <SplitImage src={mainImage} alt={service.mainImage.alt}>
            <h1>{service.title}</h1>

            <BlockContent body={service._rawBody} />

            <NavButton className="cta" to="/contact-us">
              Talk to an Instructor
            </NavButton>
          </SplitImage>
        )}
      </PageWrapper>
    </Layout>
  )
}

export default ServicePage

export const query = graphql`
  query ServicePageQuery($id: String!) {
    service: sanityService(id: { eq: $id }) {
      _rawBody
      title
      seoData {
        title
        location
        _rawDescription
      }
      mainImage {
        alt
        ...SanityImage
      }
    }
  }
`
